.App {
  text-align: center;
}

.logo img{
  width: 70%;
  max-width: 40rem;
}

.searchbutton{
  height: 100%;
}

.searchbar{
  height: 3rem;
  box-shadow: 0 6px 8px rgba(0,0,0,.15);
  border-radius: 40px;
  width: 60%;
  max-width: 30rem;
  margin: auto;
  display: table;
}

.searchbar input {
  width: 80%;
  border:none;
  height: 2rem;
  font-size: large;
}

.searchbutton {
  background: transparent;
  border: 2px solid transparent;
}

.searchinput { 
  display: table-cell;
  vertical-align: middle;
}

input[type=text]:focus {
  outline: none;
}




.picturebox{
  width: 50%;
  display: flex;
  align-items: center;
}

.flexbox{
  display: flex;
}

.recipebox {
  border-radius: 5rem;
  box-shadow: 0 7px 15px 3px rgba(0,0,0,.15);
  /* box-shadow: 5px 1px 9px 2px rgba(0,0,0,.15) inset; */
  padding:1rem;
  width: 75%;
  max-width: 40rem;
  margin: 2rem auto;
}
.picturebox img{
  width: 80%;
  height: 10rem;
  object-fit: cover;
  border-radius: 2rem;
  margin: auto;
}

.materialbox{
  padding: 1rem;
  width: 40%;
}

.recipetitle{
  font-weight: bold;
}

.urlbutton{
  text-align: center;
  margin: auto;
}

.urlbutton button{
  padding: 0.5rem;
  width: 40%;
  max-width: 8rem;
  background: transparent;
  border: none;
  border-radius: 1rem;
  box-shadow: 0 6px 8px rgba(0,0,0,.35);
}

.urlbutton a{
  text-decoration: none;
  color: black;
}

.recipe {
  margin-top: 1rem;
}
